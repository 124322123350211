const Enum = {
    CorporateTax: {
        0: "Ingen information",
        1: "Registered",
        2: "DeRegistered",
        3: "NotRegistered",
    },
    VatStatus: {
        0: "Ingen information",
        1: "Active",
        2: "NotActive",
    },
    intervalOptions: {
        INTERVALL1: "Mer än 0 % men inte mer än 25 % ",
        INTERVALL2: "Mer än 25 % men inte mer än 50 %",
        INTERVALL3: "Mer än 50 % men inte mer än 75 %",
        INTERVALL4: "Mer än 75 % men mindre än 100 %",
        INTERVALL5: "100%",
    },
    controlOptions: {
        ART10: "Personen har kontroll genom aktier, andelar, medlemskap, avtal eller bestämmelse i exempelvis bolagsordning eller stadgar.",
        ART14: "Personen är styrelseledamot eller motsvarande befattningshavare.",
        ART17: "Personen företräder förvaltaren av stiftelsen.",
        ART20: "Personen har rätt att utse eller avsätta mer än hälften av styrelseledamöterna eller motsvarande befattningshavare.",
        ART25: "Personen kan, enligt stiftelseförordnandet, få en väsentlig del av de medel som stiftelsen delar ut.",
        ART30: "Personen har kontroll tillsammans med närstående.",
        ART40: "Personen har kontroll genom andra företag eller föreningar.",
        ART70: "Personen är instiftare av trusten.",
        ART71: "Personen är förvaltare av trusten eller företrädare för förvaltaren om den är en juridisk person.",
        ART72: "Personen är beskyddare av trusten.",
        ART73: "Personen är förmånstagare till trusten.",
        ART74: "Personen utövar kontroll på annat sätt.",
    },
    turnOverOptions: {
        1: "Under en halv miljon kronor",
        2: "Mellan en halv miljon och en miljon kronor",
        3: "Mellan en miljon och tre miljoner kronor",
        4: "Över tre miljoner kronor",
    },
    RoleOptions: {
        1: "Styrelseordförande",
        2: "Vd",
        3: "Styrelseledamot",
        4: "Firmatecknare eller motsvarande",
    },
    companyCode: {
        0: "Aktiebolag",
        1: "Enkhildfirma",
        2: "Enskild näringsidkare",
        3: "Other",
        4: "Ingen information",
    },
    employerStatus: {
        0: "Ingen information",
        1: "Registered",
        2: "Not Registered",
    },
    roleOptions: {
        1: "Styrelseordförande",
        2: "Vd",
        3: "Styrelseledamot",
        4: "Firmatecknare eller motsvarande",
    },
    politicalRelationship: {
        1: "Det är personen själv",
        2: "Make/Maka",
        3: "Registrerad partner",
        4: "Sambo",
        5: "Barn",
        6: "Barns maka/make/reg.partner/sambo",
        7: "Förälder",
        8: "Känd medarbetare",
    },
    typeOfPoliticalPosition: {
        1: "Statschef",
        2: "Statsminister eller övrig minister i regeringskansliet",
        3: "Riksdagsledamot/parlamentsledamot",
        4: "Domare i Högsta domstolen eller Högsta förvaltningsdomstolen",
        5: "Riksrevisor",
        6: "Ledamot av Riksbankens direktion",
        7: "Styrelseledamot i centralbank",
        8: "Ambassadör",
        9: "Diplomatiskt sändebud",
        10: "Hög officerare (general, generallöjtnant, generalmajor, anmiral, viceamiral och konteramiral)",
        11: "Styrelseledamot och dennes ersättare eller VD och dennes ersättare i statsägda bolag",
        12: "Person i ledningen i en intrnationell organisation (styrelseledamot, generalsekreterare med flera, t ex FN, EU, NATO)",
        13: "Ledamot i styrelsen av ett politiskt parti",
    },
    boardOptions: {
        1: "Ledamot",
        2: "Suppleant",
    },
    ownershipDetailOptions: {
        1: "Privat",
        2: "Via företag",
    },
    stockInfo: {
        1: "Ja, jag äger/kontrollerar alla aktier i företaget",
        2: "Ja, det finns en eller flera som äger eller kontrollerar mer än 25 procent. Det kan till exempel vara en privatperson, en privatperson och en nårstående",
        3: "Nej, det finns ingen som äger eller kontrollerar mer än 25 procent, varken ensam eller tillsammans med näestående",
    },
    onboardingStatus: {
        0: "Initial",
        1: "Klar att granska",
        2: "Godkänd",
        3: "Nekad",
    },
    companyTypeEnum: {
        0: "None",
        1: "EnskildFirma",
        5: "Aktiebolag",
    },
    expecetdTurnover: {
        1: "Under en halv miljon kronor",
        2: "Mellan en halv miljon och en miljon kronor",
        3: "Mellan en miljon och tre miljoner kronor",
        4: "Över tre miljoner kronor",
    },
    riskYesOrNo: {
        1: "Nej",
        2: "Ja",
    },
    yesOrNo: {
        0: "Nej",
        1: "Ja",
    },
    yesNoOption: {
        false: "Nej",
        true: "Ja",
    },
    vatPeriod: {
        0: "Ej valt",
        1: "Årsvis i anslutning till inkomstdeklaration",
        2: "Årsvis (handel med andra länder)",
        3: "Kvartalsvis",
        4: "Månadsvis",
    },
    numberofEmployees: {
        0: "Ej valt",
        1: "1",
        2: "1-4",
        3: ">5",
    },
};
export default Enum;
