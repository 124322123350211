import Vue from "vue";
export default {
    getKycByClientList: async () => {
        const response = await Vue.axios.get(`kycByClient/kycByClientList`);
        return response.data;
    },
    getListByClientId: async clientId => {
        const response = await Vue.axios.get(`kycByClient/list/${clientId}`);
        return response.data;
    },
    getkycByClientIndividualList: async clientId => {
        const response = await Vue.axios.get(`kycByClient/kycByClientIndividualList?clientId=${clientId}`);
        return response.data;
    },
    kycByClientDetails: async clientId => {
        const response = await Vue.axios.get(`kycByClient/kycByClientDetails?clientId=${clientId}`);
        return response.data;
    },
    clientDetails: async clientId => {
        const response = await Vue.axios.get(`kycByClient/clientDetails?clientId=${clientId}`);
        return response.data;
    },
    snicode: async clientId => {
        const response = await Vue.axios.get(`kycByClient/sniCode?clientId=${clientId}`);
        return response.data;
    },
    copyData: async clientId => {
        const response = await Vue.axios.get(`kycByClient/copyInformation?clientId=${clientId}`);
        return response.data;
    },
    getCountryList: async () => {
        try {
            const { data } = await Vue.axios.get(`clients/countryList`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getCountryList: ~ e", e);
            return [];
        }
    },
};
