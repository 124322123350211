import Vue from "vue";

export default {
    getAllAnnualReports: async clientId => {
        const response = await Vue.axios.get(`annualReports/${clientId}`);
        return response.data;
    },
    getAllFinancialYears: async clientId => {
        const response = await Vue.axios.get(`annualReports/${clientId}/financialYears`);
        return response.data;
    },
    createNewAnnualReport: async (clientId, financialYearId) => {
        const response = await Vue.axios.post(`annualReports/${financialYearId}`, null, {
            params: {
                clientId,
            },
        });
        return response.data;
    },
    deleteAnnualReport: async annualReportId => {
        const response = await Vue.axios.delete(`annualReports/${annualReportId}`);
        return response.data;
    },

    getCompanyInfo: async (clientId, annualReportId) => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/companyInfo`, {
            params: {
                clientId,
            },
        });
        return response.data;
    },
    createCompanyInfo: async (clientId, annualReportId) => {
        const response = await Vue.axios.post(`annualReports/${annualReportId}/companyInfo`, null, {
            params: {
                clientId,
            },
        });
        return response.data;
    },
    updateCompanyInfo: async (annualReportId, data) => {
        console.log("updateCompanyInfo data", data);
        const response = await Vue.axios.put(`annualReports/${annualReportId}/companyInfo`, data);
        return response.data;
    },
    getCoverSheetDetails: async annualReportId => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/coverSheetDetails`);
        return response.data;
    },
    updateCoverSheetDetails: async (annualReportId, data) => {
        const response = await Vue.axios.put(`annualReports/${annualReportId}/coverSheetDetails`, data);
        return response.data;
    },
    getManagementReportDetails: async annualReportId => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/managementReport`);
        return response.data;
    },
    getManagementReportSyna: async (annualReportId, getData) => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/managementReportSyna`, {
            params: {
                getData,
            },
        });
        return response.data;
    },
    getManagementReportBooking: async (annualReportId, getData) => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/managementReportBooking`, {
            params: {
                getData,
            },
        });
        return response.data;
    },
    createManagementReport: async annualReportId => {
        const response = await Vue.axios.post(`annualReports/${annualReportId}/managementReport`);
        return response.data;
    },
    updateManagementReportDetails: async (annualReportId, data) => {
        const response = await Vue.axios.put(`annualReports/${annualReportId}/managementReport`, data);
        return response.data;
    },
    getIncomeStatementDetails: async annualReportId => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/incomeStatement`);
        return response.data;
    },
    createIncomeStatement: async (annualReportId, clientId, startDate, endDate, sruReset) => {
        const response = await Vue.axios.post(`annualReports/${annualReportId}/incomeStatement`, null, {
            params: {
                clientId,
                startDate,
                endDate,
                sruReset,
            },
        });
        return response.data;
    },
    updateIncomeStatementDetails: async (annualReportId, data) => {
        const response = await Vue.axios.put(`annualReports/${annualReportId}/incomeStatement`, data);
        return response.data;
    },
    getBalanceSheetDetails: async annualReportId => {
        const response = await Vue.axios.get(`annualReports/${annualReportId}/balanceSheet`);
        return response.data;
    },
    createBalanceSheet: async (annualReportId, clientId, startDate, endDate, sruReset) => {
        const response = await Vue.axios.post(`annualReports/${annualReportId}/balanceSheet`, null, {
            params: {
                clientId,
                startDate,
                endDate,
                sruReset,
            },
        });
        return response.data;
    },
    updateBalanceSheetDetails: async (annualReportId, data) => {
        const response = await Vue.axios.put(`annualReports/${annualReportId}/balanceSheet`, data);
        return response.data;
    },
};
