const Enum = {
    kycByClientStatus: {
        0: "Utkast",
        1: "Inskickad",
        2: "Godkänd",
        3: "Ingen åtgärd krävs",
        4: "Ej påbörjad",
    },
    PageStatus: {
        Utkast: 0,
        Inskickad: 1,
        Godkänd: 2,
        IngenAtgärdKrävs: 3,
        EjPåbörjad: 4,
    },
};
export default Enum;
